import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { Col, Row, Button, Form } from 'react-bootstrap';
import { Autocomplete, TextField } from '@mui/material';
import { startNewDroplet, deleteDroplet } from '../api/apiUser';
import axios from 'axios';
import CardComponent from './CardComponent';
import Swal from 'sweetalert2';
import { getToken } from '../api/apiUser';

export default function PostCard(selectedDeck) {
  const [error, setError] = useState(null);
  const [word, setWord] = useState(null);
  const [translateFrom, setTranslateFrom] = useState('');
  const [translateTo, setTranslateTo] = useState('');
  const [cards, setCards] = useState([]);
  const [generateImage, setGenerateImage] = useState(false); // New state for Generate Image

  const commonLanguages = [
    { code: 'English', label: 'English' },
    { code: 'Spanish', label: 'Spanish' },
    { code: 'French', label: 'French' },
    { code: 'German', label: 'German' },
    { code: 'Italian', label: 'Italian' },
    { code: 'Chinese', label: 'Chinese' },
    { code: 'Japanese', label: 'Japanese' },
    { code: 'Russian', label: 'Russian' },
    { code: 'Arabic', label: 'Arabic' }
  ];

  const updateCards = (obj, index) => {
    setCards((prevCards) => {
      const updatedCards = [...prevCards];
  
      if (index < 0) {
        updatedCards.push(obj);
        return updatedCards;
      }
  
      if (index >= 0 && index < updatedCards.length) {
        updatedCards[index] = {
          ...updatedCards[index], // Preserve existing properties
          ...obj, // Overwrite with new properties
        };
        return updatedCards;
      }
  
      console.warn(`Index ${index} is out of bounds. No changes made.`);
      return updatedCards;
    });
  
    // Return the operated index
    return index < 0 ? cards.length : index;
  };
  

  const postCard = async () => {
    setError(null);
    Swal.fire({
      icon: 'success',
      title: 'Generating card...',
      text: 'AI is making your card...',
    });
    try {
      const deckName = selectedDeck.selectedDeck;
      const index = updateCards({
        loading: true,
        visible: true,
      }, -1); //make a new card with -1 and get the index

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/post_card`, {
        word,
        translateFrom,
        translateTo,
        deckName,
        generateImage, 
      }, {
        headers: {
          'Access-Control-Allow-Origin': 'http://localhost:3000',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getToken()}`,
        }
      });
      console.log('response:', response);

      updateCards({
        deck_name: response.data.data.deck_name,
        english_word: response.data.data.english_word,
        non_english_word: response.data.data.non_english_word,
        english_sentences: response.data.data.english_sentences,
        non_english_sentences: response.data.data.non_english_sentences,
        img_url: response.data.data.img_url,
        original_english_word: response.data.data.english_word,
        original_non_english_word: response.data.data.non_english_word,
        loading: false,
        visible: true,
      }, index); //update the loading card

    } catch (error) {
      setError('Failed: ' + error.message);
    } finally {
      console.log('Success')
    }
  };

  const uploadCard = async (index) => {
    console.log('Uploading Card');
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/upload_card`, cards[index], {
        headers: {
          'Access-Control-Allow-Origin': 'http://localhost:3000',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getToken()}`,
        }
      });
      Swal.fire({
        icon: 'success',
        title: 'Card Uploaded!',
        text: 'The card was successfully uploaded',
      });
      console.log(response);
      updateCards({visible:false}, index);

      // Log the updated state after the update
      setTimeout(() => {
        console.log(cards,index); // This ensures the log reflects the updated state
      }, 0);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Card Upload Error',
        text: 'The card failed to upload',
      });
      console.log(error);
    }
  };

  const rejectCard = async (index) => {
    console.log('reject');
    Swal.fire({
      icon: 'success',
      title: 'Card Rejected',
      text: 'You rejected the card. Generate another.',
    });
    updateCards({visible:false}, index);

    // Log the updated state after the update
    setTimeout(() => {
      console.log(cards, index); // This ensures the log reflects the updated state
    }, 0);
  };

  const editCard = (newVal, field, index) => {
    if (index < 0 || index >= cards.length) {
      console.error(`Invalid index: ${index}`);
      return;
    }
    const card = cards[index];
    if (!card) {
      console.error(`Card at index ${index} is undefined`);
      return;
    }
    let editedCards = [...cards];
    editedCards[index] = {
      ...card,
      [field]: newVal,
    };
    updateCards(editedCards[index], index);
  };
  
  
  return (
    <Container className="mt-3">
      <Row className="mb-4 p-4 pb-3 bg-body-tertiary hover-effect" style={{ borderRadius: '10px', justifyContent: 'center' }}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {/* Input box for the word */}
        <Form.Group className="mb-3" controlId="formWordInput">
          <Form.Label>Word</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter word"
            value={word}
            onChange={(e) => setWord(e.target.value)}
            style={{ width: "200px", justifyContent: 'center' }}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formTranslateFrom">
          <Form.Label>Translate From</Form.Label>
          <Form.Select
            value={translateFrom}
            onChange={(e) => setTranslateFrom(e.target.value)}
            style={{ width: "200px", justifyContent: 'center' }}
          >
            <option value="">Select Language</option>
            {commonLanguages.map((language) => (
              <option key={language.code} value={language.code}>
                {language.label}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formTranslateTo">
          <Form.Label>Translate To</Form.Label>
          <Form.Select
            value={translateTo}
            onChange={(e) => setTranslateTo(e.target.value)}
            style={{ width: "200px", justifyContent: 'center' }}
          >
            <option value="">Select Language</option>
            {commonLanguages.map((language) => (
              <option key={language.code} value={language.code}>
                {language.label}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        </div>    
        <div style={{ display: 'flex', alignItems: 'center', gap: '16px', justifyContent: "center" }}>
          <Button
            onClick={postCard}
            style={{ width: "200px" }}
            disabled={!word || !selectedDeck}
          >
            Post Card
          </Button>
          <Form.Group className="mb-3" controlId="formGenerateImage" style={{ margin: 0 }}>
            <Form.Check
              type="checkbox"
              label="Generate Image"
              checked={generateImage}
              onChange={(e) => setGenerateImage(e.target.checked)}
            />
          </Form.Group>
        </div>

        {error && <p className="text-danger mt-3">Error: {error}</p>}
        <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center', // Always center the cards horizontally
              alignItems: 'center', // Center vertically (optional)
              gap: '16px', // Space between cards
            }}
          >
            {cards.map((card, index) => (
              card.visible && (
                <div key={index} style={{ flex: '1 0 300px', maxWidth: '300px' }}>
                  <CardComponent
                    card={card}
                    index={index}
                    uploadFunc={uploadCard}
                    rejectFunc={rejectCard}
                    editFunc={editCard}
                  />
                </div>
              )
            ))}
          </div>


      </Row>
    </Container>
  );
}
