import React, { useState } from 'react';
import './CardComponent.css';

const CardComponent = ({ card, index, uploadFunc, rejectFunc, editFunc }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div className="card-container">
      <div className="card-wrapper">
        {card?.loading ? ( // Show loading circle if loading is true
          <div className="loading-container">
            <div className="loading-spinner"></div>
          </div>
        ) : (
          <div className={`flip-card ${isFlipped ? 'flipped' : ''}`}>
            <div className="flip-card-inner">
              {/* Front Side (English Side) */}
              <div className="flip-card-front">
                <div className="card-content">
                  <input
                    type="text"
                    value={card.english_word}
                    onChange={(e) => editFunc(e.target.value, 'english_word', index)} // Update state as user types
                  />
                  {/* Map over the array of English sentences */}
                  {card.english_sentences.map((sentence, index) => (
                    <p key={index}>{sentence}</p>
                  ))}
                  {card.img_url && (
                    <div className="image-section">
                      <img src={card.img_url} alt="Card Image" className="card-image" />
                    </div>
                  )}
                </div>
              </div>

              {/* Back Side (Non-English Side) */}
              <div className="flip-card-back">
                <div className="card-content">
                  <input
                    type="text"
                    value={card.non_english_word}
                    onChange={(e) => editFunc(e.target.value, 'non_english_word', index)} // Update state as user types
                  />
                  {/* Map over the array of Non-English sentences */}
                  {card.non_english_sentences.map((sentence, index) => (
                    <p key={index}>{sentence}</p>
                  ))}
                </div>
              </div>
            </div>

            {/* Checkmark and X buttons */}
            <div className="card-actions">
              <button
                className="reject-button"
                onClick={() => {
                  rejectFunc(index);
                }}
              >
                ✖
              </button>
              <button
                className="accept-button"
                onClick={() => {
                  uploadFunc(index);
                }}
              >
                ✔
              </button>
            </div>
          </div>
        )}
      </div>

      {/* Flip Button */}
      {!card?.loading && (
        <button className="flip-button" onClick={handleFlip}>
          {"Flip Card"}
        </button>
      )}
    </div>
  );
};

export default CardComponent;
