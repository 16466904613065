import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { Col, Row, Button, Form } from 'react-bootstrap';
import { Autocomplete, TextField } from '@mui/material';
import { startNewDroplet, deleteDroplet } from '../api/apiUser';
import axios from 'axios';
import { getToken } from '../api/apiUser';

export default function MakeDeck() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [name, setName] = useState(null);

  const makeDeck = async () => {
    setLoading(true);
    setError(null);
  
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/make_deck`, {
        name
      }, {
        headers: {
          'Access-Control-Allow-Origin': 'http://localhost:3000', // Adjust origin if needed
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getToken()}`,
        }
      });
      console.log('response:', response);

    } catch (error) {
      setError('Failed: ' + error.message);
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <Container className="mt-3">
      <Row className="mb-4 p-4 pb-3 bg-body-tertiary hover-effect" style={{ borderRadius: '10px', justifyContent: 'center'}}>
        {/* Input box for the word */}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Form.Group className="mb-3" controlId="formWordInput">
          <Form.Label>Deck Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter word"
            value={name}
            style={{ width: "300px" }}
            onChange={(e) => setName(e.target.value)} // Update word state
          />
        </Form.Group>

        </div>
        <Button onClick={makeDeck} disabled={loading || !name} style={{ width: "300px" }}> {/* Disable if word or deck is empty */}
          {loading ? 'Making Deck...' : 'Make Deck'}
        </Button>
        {error && <p className="text-danger mt-3">Error: {error}</p>}
        
      </Row>
    </Container>
  );
}
