import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { Col, Row, Button, Form } from 'react-bootstrap';
import { Autocomplete, TextField } from '@mui/material';
import { startNewDroplet, deleteDroplet } from '../api/apiUser';
import PostCard from './PostCard';
import MakeDeck from './MakeDeck';
import axios from 'axios';
import Swal from 'sweetalert2';
import { getToken, isPaid } from '../api/apiUser';
import CheckoutButton from './CheckoutButton';
import Pay from './Pay';


export default function Home() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [decks, setDecks] = useState([]);
  const [selectedDeck, setSelectedDeck] = useState(null);

  useEffect( () => {
    const fetchDecks = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/get_decks`,{
          headers: {
            Authorization: 'Bearer ' + getToken()
          }
        });
        console.log(response);
        setDecks(response.data.decks);
      } catch (error) {
        console.log('error fetching decks: ', error);
      }
    };
    void fetchDecks();
  }, []);

  const syncAnki = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/sync`, {
        headers: {
          Authorization: 'Bearer ' + getToken()
        }
      });
  
      if (response.data.error) {
        console.log(`Failed to sync: ${response.data.error}`);
        Swal.fire({
          icon: 'error',
          title: 'Sync Failed',
          text: `Failed to sync: ${response.data.error}`,
        });
      } else {
        console.log("Sync successful");
        Swal.fire({
          icon: 'success',
          title: 'Sync Successful',
          text: 'Your Anki data has been successfully synced!',
        });
      }
    } catch (error) {
      console.error("Failed to sync: " + error.message);
      Swal.fire({
        icon: 'error',
        title: 'Sync Failed',
        text: `Failed to sync: ${error.message}`,
      });
    }
  };
  

  
  
  return (
    <Container className="mt-3">
      <div
        style={{
          display: 'flex',
          justifyContent: 'center', // Centers horizontally
          //alignItems: 'center', // Centers vertically
          minHeight: '100vh', // Makes the parent container full screen height
        }}
      >
      <Row className="mb-4 p-4 pb-3 bg-body-tertiary hover-effect" style={{ borderRadius: '10px',justifyContent: 'center' }}>
      { getToken() ?
      <>
        { localStorage.getItem("isPaid") == "true"  ? (
        <> 
        {/* Dropdown with search feature for selecting a deck */}
          <Autocomplete
            disablePortal
            options={decks}
            getOptionLabel={(option) => option} // Assuming the option is the deck name
            renderInput={(params) => <TextField {...params} label="Select Deck" />}
            value={selectedDeck}
            onChange={(event, newValue) => setSelectedDeck(newValue)} // Update selectedDeck state
            sx={{ mb: 3, width: 300 }} // Adjust width and margin
          />
          {selectedDeck ? (
          <>

          <Row className="mb-4 p-4 pb-3 bg-body-tertiary hover-effect">
            <div style={{ border: '1px solid black', padding: '16px', borderRadius: '8px' }}>
              <PostCard selectedDeck={selectedDeck} />
            </div>
          </Row>
        
        <Button onClick={() => syncAnki()} disabled={loading} style={{ width: "200px"}}>
          {loading ? 'Syncing...' : 'Sync'}
        </Button>
        <MakeDeck/>
        </>
        ) : (
          <></>

        )}
        </> )
        :(
        <Pay/>
        )
        }
      </>
        :
        <>Log in to use the app.</>
      }
        {error && <p className="text-danger mt-3">Error: {error}</p>}
      </Row>
    </div>
    </Container>
  );
}
